<template>
  <v-toolbar color="transparent" flat height="55">
    <div class="d-flex">
      <v-query-builder class="ml-n3" :query-map="filters" model="branches" />
      <div class="d-flex align-center ml-6 subtitle-2 text--secondary font-weight-medium">
        Ordenar por
        <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-2" v-bind="attrs" v-on="on" outlined :ripple="false">
              <span class="subtitle-2 text--secondary">{{currentSort.label}}</span>
              <v-icon color="secondary" size="20" right>{{ currentSort.id.search('ascending')  ? 'mdi-arrow-down' : 'mdi-arrow-up'}}</v-icon></v-btn>
          </template>
          <v-list class="pa-0">
            <v-list-item class="px-5 py-1" :ripple="false" v-for="sort in sortList" :key="sort.id" @click="$emit('update:currentSort', sort)">
              <v-list-item-title class="subtitle-2 text--secondary font-weight-medium">
                {{sort.label}}
                <v-icon color="secondary" size="20" right>{{ sort.id.search('ascending') ? 'mdi-arrow-down' : 'mdi-arrow-up'}}</v-icon>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <v-spacer />
    <v-btn class="mr-n3" color="primary" @click="$emit('newItem')"><v-icon left small>mdi-plus-box-multiple</v-icon>Nuevo</v-btn>
  </v-toolbar>
</template>

<script>
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'

export default {
  components: {
    VQueryBuilder
  },
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    sortList: {
      type: Array,
      default: () => []
    },
    currentSort: {
      type: Object,
      default: () => {}
    }
  }
}
</script>