<template>
  <v-row>
     <!-- header -->
   <mini-header :list="status" :filter.sync="filter" :status="status" :breadcrumbs="breadcrumbs" :hiddenTab="true" :dense="true" :scroll="scroll" iconAllTab="mdi-printer-pos">
      <template v-slot:main>
          <v-query-builder class="ml-2" :query-map="filters" model="pos" :active.sync="activeFilter" />
        </template>
      <template v-slot:secondary>
        <v-btn class="ml-2" width="35" outlined @click="mode = !mode">
          <v-icon size="19">mdi-{{ mode ? 'view-grid-outline' : 'table'}}</v-icon>
        </v-btn>
        <template>
          <v-menu  v-if="mode" close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-2" outlined v-bind="attrs" v-on="on">
                <v-icon left>mdi-checkbox-outline </v-icon>Selecciona<v-icon size="18" right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list class="pa-0">
              <v-list-item class="px-3" @click="actionSelected = 'todos'" :ripple="false">
                <v-list-item-title class="body-2">Todos</v-list-item-title>
              </v-list-item>
              <v-list-item class="px-3" @click="actionSelected = 'filtrados'" :ripple="false">
                <v-list-item-title class="body-2">Filtrados</v-list-item-title>
              </v-list-item>
              <v-list-item class="px-3" @click="actionSelected = 'primera página'" :ripple="false">
                <v-list-item-title class="body-2">Primera página</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-2" outlined v-bind="attrs" v-on="on">
                <v-icon left>mdi-plus-box </v-icon>Opciones<v-icon size="18" right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list class="pa-0">
              <v-list-item class="px-3" @click="handleGroup('create')" :ripple="false" :disabled="!selectedList.length">
                <v-list-item-title class="body-2">Crear grupo</v-list-item-title>
              </v-list-item>
              <v-list-item class="px-3" @click="handleGroup('add')" :ripple="false" :disabled="!selectedList.length">
                <v-list-item-title class="body-2">Agregar grupo</v-list-item-title>
              </v-list-item>
              <!-- <v-tooltip color="bgSearch" right max-width="185px" transition="scale-transition" open-delay="1000">
                <template v-slot:activator="{ on }">
                  <span class="fontBody--text body-1" v-on="!selectedDocuments.length ? on : false">
                    <v-list-item class="px-3" @click="sendEmailDialog=true" :ripple="false" :disabled="!selectedDocuments.length">
                      <v-list-item-title class="body-2">Agregar a grupo</v-list-item-title>
                    </v-list-item>
                  </span>
                </template>
                <span class="d-block px-3 py-2">Debe seleccionar documentos antes de seleccionar una opción</span>
              </v-tooltip> -->
            </v-list>
          </v-menu>
          <v-query-builder class="ml-2" :query-map="filters" model="pos" :disabled="count === 0" />
        </template>
      </template>
   </mini-header>
   <!-- end header -->

    <v-col class="px-0"> <!-- mt-20 pt-16 -->
      <VSkeleton v-if="loading" />
      <template v-else>
        <v-empty-state v-if="!posList?.length || Object.keys($route?.query)?.length === 0"  type="pos" id="pos" customClass="mt-3" :hiddenDescription="hiddenBtnEmpty">
          <template v-slot:actions>
            <template v-if="!$route.query.ordering && hiddenBtnEmpty">
              <v-btn @click="activeFilter=true" color="primary" :ripple="false" :width="153"><v-icon class="mr-2">mdi-filter-plus</v-icon>Filtrar</v-btn>
              <v-btn class="ml-2" @click="latestRecords('-created')" outlined :ripple="false" width="153"><v-icon class="mr-2">mdi-text-search-variant</v-icon>Ver POS</v-btn>
            </template>
            </template>
        </v-empty-state>
        <template v-else>
          <v-col cols="12" class="px-0 pt-0" v-if="mode">
            <VCardList class="pb-16" :list="posList" :complements="branchesList" :chipStatus="chipStatus" :selectedList.sync="selectedList" :actionSelected="actionSelected" />
            <!-- <v-col class="pa-0" v-if="showPagination">
              <VMainPagination :currentPage.sync="page" :showPagination="showPagination" :page="page" :totalPages="totalPages" :count="count" />
            </v-col> -->
          </v-col>
          <template v-else>
            <v-data-table
              :class="{'fixed-table' : $store.state.base.isExpandNavigationDrawer}"
              v-if="posList.length"
              :headers="headers"
              :items="posList"
              :items-per-page="pagination"
              hide-default-header
              disable-sort
              mobile-breakpoint
              hide-default-footer
              style="cursor: pointer"
              v-model="selected"
              item-key="id"
              show-select
              checkbox-color="primary"
              fixed-header
              :height="heightTable"
            >
              <!-- header -->
              <template v-slot:header="{ props: { headers } }">
                <VTableHeaders :headers="headers" @sort="setSort" @toggleAll="toggleAll" @isSelected="isSelected = $event" :resetCheckbox="resetCheckbox" @toggleDropdown="toggleDropdown = $event" />
                <v-divider style="position: absolute;  margin-top: -6px; margin-left: -5px ;z-index: 2; min-width: 100%" />
              </template>
              <!-- end header -->
              <template v-slot:[`item.name`]="{item}">
                <span class="body-1 mb-n2 d-inline-block text-truncate" v-if="item.name" style="max-width: 95%;">{{(item || {}).name}}</span>
                <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
              </template>
              <template v-slot:[`item.branch`]="{item}">
                <span class="text-decoration-none fontBody--text">{{nameBranch(item.branch)}}</span>
              </template>
                <template v-slot:[`item.custom_id`]="{item}">
                <span class="body-1">{{item.custom_id || '-'}}</span>
              </template>
              <template v-slot:[`item.status`]="{item}">
                <v-chip :color="chipStatus[item.status].color" small label>
                  <span class="font-weight-semibold">{{chipStatus[item.status].name}}</span>
                  <v-icon right size="18">{{chipStatus[item.status].icon}}</v-icon>
                </v-chip>
              </template>
              <template v-slot:[`item.actions`]="{item}">
                <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" :nudge-left="10" transition="slide-y-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mr-n4" v-bind="attrs" v-on="on" text x-small><v-icon color="fontBody" size="24">mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list class="pa-0">
                    <v-list-item class="px-3" @click="hanldeTableAddToGroup(item)" :ripple="false">
                      <v-list-item-title class="body-2">Agregar a grupo</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
            <VMainPagination v-if="posList.length" titleShowAll="Ver todos los documentos" :count="count" redirect="purchasesList" />
          </template>
        </template>
      </template>
    </v-col>

    <!-- dialog new group -->
     <v-dialog v-model="dialogGroup" width="650" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Crear grupo</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogGroup=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pb-4 pt-6 px-5" style="max-height: 600px !important;">
          <v-list class="pa-0 transparent v-list-form-extended">
            <v-list-item class="py-1">
              <v-list-item-title>Nombre</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="$v.newGroup.name.$model" :error="$v.newGroup.name.$error" outlined required single-line dense maxlength="100" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Descripción</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="$v.newGroup.description.$model" :error="$v.newGroup.description.$error" outlined required single-line dense maxlength="100" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <div class="">
            <span class="d-block mt-5 body-1 fontBody--text font-weight-bold">Visibilidad</span>
            <v-radio-group class="mx-0 ml-n1" v-model="newGroup.visibility" dense hide-details>
              <v-radio label="Todos los miembros de la organización" :value="1" hide-details />
              <v-radio label="Algunos miembros de la organización" :value="2" hide-details />
              <v-radio label="Solo yo" :value="3" hide-details />
            </v-radio-group>
          </div>
          <template v-if="![1, 3].includes(newGroup.visibility)">
            <v-list class="pa-0 mt-5 transparent v-list-form-extended">
              <v-list-item class="py-1">
                <v-list-item-title>Compartir con</v-list-item-title>
                <v-list-item-subtitle>
                  <v-col class="pa-0">
                    <v-autocomplete
                      v-model="newGroup.members"
                      :error="$v.newGroup.members.$error"
                      hide-details
                      single-line
                      color="primary"
                      dense
                      outlined
                      :items="membersList"
                      multiple
                      :chips="false"
                      item-text="email"
                      item-value="email"
                      return-object
                      :prefix="newGroup.members.length >= 1 ? `${newGroup.members.length === 1 ? `${newGroup.members.length} seleccionado` : `${newGroup.members.length} seleccionados`}` : ''"
                      no-data-text="No existen miembros para mostrar"
                    >
                      <template v-slot:selection="{ props }">
                        <span v-if="props" style=""></span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
            <v-row>
              <v-col cols="2"></v-col>
              <v-col class="pl-9 mt-2">
                <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" v-for="(member, i) in newGroup.members" :key="`${member}${i}`" @click:close="memberRemove(member)" color="lightBlue" small label closable close-icon="mdi-close">
                  {{ member.email }}
                </v-chip>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogGroup=false">Cancelar</v-btn>
          <v-btn color="primary" @click="createGroup" :loading="createGroupLoading">Crear</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog new group -->

    <!-- dialog add to group -->
    <v-dialog v-model="dialogAddGroup" width="650" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Agregar a grupo</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogAddGroup=false, groups=[]" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pb-7 pt-5 px-5" style="max-height: 600px !important;">
          <v-list class="pa-0 mt-5 transparent v-list-form-extended">
            <v-list-item class="py-1">
              <v-list-item-title>Grupo</v-list-item-title>
              <v-list-item-subtitle>
                <v-col class="pa-0">
                  <v-autocomplete
                    v-model="groups"
                    hide-details
                    single-line
                    color="primary"
                    dense
                    outlined
                    :items="pos_groupsList"
                    multiple
                    :chips="false"
                    item-text="name"
                    item-value="id"
                    return-object
                    :prefix="groups.length >= 1 ? `${groups.length === 1 ? `${groups.length} seleccionado` : `${groups.length} seleccionados`}` : ''"
                    no-data-text="No existen grupos para mostrar"
                  >
                    <template v-slot:selection="{ props }">
                      <span v-if="props" style=""></span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <v-row>
            <v-col cols="2"></v-col>
            <v-col class="pl-9 mt-2">
              <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" v-for="(g, i) in groups" :key="`${g}${i}`" @click:close="groupsRemove(g)" color="lightBlue" small label closable close-icon="mdi-close">
                {{ g.name }}
              </v-chip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogAddGroup=false, groups=[]">Cancelar</v-btn>
          <v-btn color="primary" @click="addToGroup" :loading="createGroupLoading">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog add to group -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { groupBy } from 'lodash'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import MiniHeader from '@/components/commons/MiniHeader'
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators'
import VEmptyState from '@/components/commons/VEmptyState'
import VCardList from '@/modules/pos/pos/components/complements/VCardList'
import VHeader from '@/modules/pos/pos/components/complements/VHeader'
import VSkeleton from '@/modules/pos/pos/components/complements/VSkeletonPos'
import ListViewMixin from '@/mixins/ListViewMixin'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import VMainPagination from '@/components/commons/VMainPagination'

export default {
  components: {
    VTableHeaders,
    SkeletonList,
    VEmptyState,
    VCardList,
    VHeader,
    VSkeleton,
    VQueryBuilder,
    VMainPagination,
    MiniHeader
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    activeFilter: false,
    toggleDropdown: null,
    resetCheckbox: 0,
    selected: [],
    mode: true,
    groups: [],
    createGroupLoading: false,
    dialogAddGroup: false,
    dialogGroup: false,
    actionSelected: '',
    selectedList: [],
    // nameBranch: '',
    scroll: 0,
    loading: false,
    chipStatus: {
      1: {
        name: 'Activo',
        icon: 'mdi-cloud',
        color: 'lightBlue'
      },
      // degraded: {
      //   name: 'Con problemas',
      //   icon: 'mdi-cloud-remove',
      //   color: 'redLight'
      // },
      2: {
        name: 'Apagado',
        icon: 'mdi-cloud-off',
        color: 'greyMedium'
      }
    },
    filters: [
    {
        id: 'branch_id',
        label: 'Tienda',
        choices: [],
        type: 'select',
        value: null,
        category: 'a'
      },
      {
        id: 'name',
        label: 'Nombre',
        operators: [],
        type: 'text',
        value: null,
        category: 'b'
      }
    ],
    dialogPos: false,
    newGroup: {
      name: '',
      visibility: 3,
      description: '',
      pos: [],
      members: []
    },
    currentSort: {
      label: 'Nombre',
      id: 'ascending_name'
    },
    sortList: [
      {
        label: 'Nombre',
        id: 'ascending_name'
      },
      {
        label: 'Nombre',
        id: 'descending _name'
      },
      {
        label: 'Comuna',
        id: 'ascending_state'
      },
      {
        label: 'Comuna',
        id: 'descending _state'
      }
    ],
    breadcrumbs: {
      main: 'Tiendas',
      children: [
        {
          text: 'Puntos de ventas'
        }
      ]
    },
    filter: 'Todos',
    status: [
      {
        key: 'Activo',
        icon: 'mdi-cloud'
      },
      {
        key: 'Inactivo',
        icon: 'mdi-cloud-off'
      }
    ],
    env: process.env?.VUE_APP_MODE ?? 'ax',
    headers: [
      { text: 'POS', value: 'name', sortable: true },
      { text: 'Tienda', value: 'branch', width: 280, sortable: true },
      { text: 'ID interno', value: 'custom_id', sortable: true },
      { text: 'Estado', value: 'status', width: 180, sortable: true },
      { text: '', value: 'actions', align: 'end', width: 16 }
    ],
    posList: []
  }),
  watch: {
   filter (val) {
     if (val > 0) {
       this.$router.replace({name: this.$route.name, query: {status: val} }).catch(() => {})
     } else this.$router.replace({name: this.$route.name, query: null }).catch(() => {})
   },
   mode () {
    this.selectedList = []
   },
   selected (val) {
      if (val.length !== this.posList.length) this.resetCheckbox += 1

      this.selectedList = val.map(({id}) => id)

      // this.$emit('update:selectedDocuments', this.selected)
    },
    toggleDropdown (val) {
      if (['filtered', 'perPage', 'all'].includes(val)) {
        this.selected = this.posList
      } else if (val === '') this.selected = []
    }
 },
  computed: {
    ...mapState({
      count: state => state.pos.posCount,
      // posList: state => state.pos.posList,
      branchesList: state => state.branches.branchesList,
      membersList: state => state.accounts.membersList,
      pos_groupsList: state => state.pos.pos_groupsList
    }),
    statusTab () {
      return Object.entries(groupBy(this.purchaseStatuses, 'summary')).map(([key, value]) => {
        return { key, count: value.length, children: value }
      })
    },
    nameBranch () {
      return (id = '') => {
        return this.branchesList.find(item => item.id === id)?.name ?? ''
      }
    },
    heightTable () {
      if (this.$vuetify.breakpoint.height <= 1200 && this.posList.length >= 16 && this.posList.length <= 20) return 'calc(100vh - 210px)'
      if (this.posList.length > 20) return 'calc(100vh - 165px)'
      if (this.posList.length < 16) return 'auto'
      return 'calc(100vh - 275px)'
    },
    hiddenBtnEmpty () {
      if (!Object.keys(this.$route.query ?? {}).length) return true
      else if (!this.posList?.length) return false
      return false
    }
  },
  created () {
    let query = JSON.parse(JSON.stringify(this.$route.query))
    if (this.$route.query.name) {
      delete query.name
    }
    if (Object.keys(query)?.length) {
      this.getList()
      this.$store.dispatch('branches/LIST', {
        resource: 'pos-app/branches',
        query
      })
      .then(({data}) => {
        this.filters[0].choices = data.results.map(({id, name}) => {
          return {
            label: name,
            value: id
          }
        })
        if (this.$route.query.branch) {
          this.breadcrumbs.children.push({ text1: this.$route.query.name, route1: { name: 'BranchRetrieveUpdate', params: { id: this.$route.query.branch }}})
        }
      })
    }
  },
  methods: {
    hanldeTableAddToGroup (item = {}) {
      this.selectedList.push(item.id)
      this.handleGroup('add')
    },
    toggleAll () {
      this.selected = !this.isSelected ? [] : this.posList
    },
    async handleGroup (action = '') {
      if (action === 'create') {
        this.dialogGroup = true
        this.$store.dispatch('accounts/LIST', {
        resource: 'members',
        query: {
          page_size: 9999,
          is_active: true
        }
      })
      } else {
        await this.$store.dispatch('pos/LIST', {
        resource: 'pos_groups',
        query: {
            page_size: 9999
          }
        })
        this.dialogAddGroup = true
      }
    },
    memberRemove (item) {
      const index = this.newGroup.members.indexOf(item)
      if (index >= 0) this.newGroup.members.splice(index, 1)
    },
    groupsRemove (item) {
      const index = this.groups.indexOf(item)
      if (index >= 0) this.groups.splice(index, 1)
    },
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    },
    getList () {
      this.loading = true
      // let query = JSON.parse(JSON.stringify(this.$route.query))
      // if (this.$route.query.name) {
      //   delete query.name
      // }

      this.$store.dispatch('pos/LIST', {
        resource: 'pos-app/cpos',
        query: this.$route.query
      })
      .then((resp) => {
        this.posList = resp.data
      })
      .finally(() => {
        this.loading = false
      })
    },
    async addToGroup () {
      this.createGroupLoading = true
      const array = this.selectedList.filter((id) => !this.groups.find(({pos}) => pos.includes(id)))
      const isMode = this.mode ? ['todos', 'filtrados'].includes(this.actionSelected) : ['filtered', 'all'].includes(this.toggleDropdown)

      await Promise.all((this.groups).map(async (item) => {
        try {
          if (!array.find((id) => item.pos.includes(id))) {
            this.$store.dispatch('pos/UPDATE', {
              resource: 'pos_groups',
              id: item.id,
              query: this.$route.query,
              payload: {
                name: item.name,
                description: item.name,
                visibility: item.visibility,
                memebers: item.menbers,
                ...(!isMode && { pos: [...item.pos, ...this.selectedList.filter((id) => !this.groups.find(({pos}) => pos.includes(id)))]})
              }
            })
          .then(() => {
              this.$dialog.message.info('Los POS han sido asignados correctamente al grupo.')
            })
            .catch((error) => {
              this.$dialog.message.error(error.response.data.message)
            })
            .finally(() => {
              this.groups = []
              this.dialogAddGroup = false
              this.createGroupLoading = false
            })
          }
        } catch (e) {
          return []
        }
      }))
    },
    createGroup () {
      this.$v.newGroup.$touch()
      if (this.$v.newGroup.$invalid) {
        return false
      }

      this.createGroupLoading = true
      let payload = this.newGroup
      payload.pos = this.selectedList
      if ([1, 3].includes(this.newGroup.visibility)) delete payload.members
      else payload.members = payload.members.map(({id}) => id)

      if (['todos', 'filtrados'].includes(this.actionSelected) || ['filtered', 'all'].includes(this.toggleDropdown)) delete payload.pos

      this.$store.dispatch('pos/CREATE', {
        resource: 'pos_groups',
        query: this.$route.query,
        payload: payload
      })
     .then(() => {
        this.$dialog.message.info('El grupo ha sido creado con éxito.')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.$v.newGroup.$reset()
        this.newGroup = {
          name: '',
          visibility: 3,
          description: '',
          pos: [],
          members: []
        }
        this.dialogGroup = false
        this.createGroupLoading = false
      })
    }
  },
  validations: {
    newGroup: {
      name: { required, maxLength: maxLength(100) },
      description: { required, maxLength: maxLength(100) },
      visibility: { required },
      members: {
      required: requiredIf(function () {
        return ![1, 3].includes(this.newGroup.visibility)
      })
      }
    },
    newPos: {
      name: { required, maxLength: maxLength(100) },
      branch: { required, maxLength: maxLength(100) },
      store_path: { required },
      irs_store_path: { required },
      status_reason: { required }
    }
  }
}
</script>